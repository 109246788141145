import styled from "styled-components"

export const QuestionNumber = styled.span`
  font-size: 20px;
  padding-right: 24px;
  font-family: "Canela Light", sans-serif;
  line-height: 24px;
`

export const Question = styled.div`
  font-family: "Canela Light", sans-serif;
  color: var(--primary-text);
  line-height: 24px;
`
export const Answer = styled.div`
  color: var(--primary-text);
  margin: 0 0 1rem 0;
  font-family: "Moderat Regular", sans-serif;
  line-height: 24px;
  padding: 0 16px 40px 0;
  a {
    line-height: 1.4rem;
    font-family: "Moderat Regular", sans-serif;
    width: max-content;
    text-decoration: none;
    font-style: italic;
    color: var(--primary-blue);
    position: relative;
    transition: width 300ms, transform 300ms;
    ::after {
      transition: width 300ms, transform 300ms;
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 1px;
      background-color: var(--primary-text);
    }
    :hover::after {
      transition: width 300ms, transform 300ms;
      width: 100%;
    }
  }
`
