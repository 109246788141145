import React, { useRef, useState } from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { withPreview } from "gatsby-source-prismic"

import { Text } from "../components/atoms"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import PageHeader from "../components/pageHeader"
import FaqNavigation from "../components/faq/faqNavigation"

import { useFAQData } from "../hooks/useFAQData"
import { Answer } from "../components/faq";

const FAQ = ({ data: previewData }) => {
  const { title, tagline, image, groups, body, pageTitle } =
    useFAQData(previewData)
  const [faqGroup, setFaqGroup] = useState<number>(0)
  const { faq_group_text, faq_title, questions_and_answers } = groups[faqGroup]
  const bookData = body[0] ? body[0].primary : undefined
  const revealRefs = useRef([])
  revealRefs.current = []
  const addToRefs = el => {
    if (el && !revealRefs.current.includes(el)) {
      revealRefs.current.push(el)
    }
  }
  const navItems = groups.map(group => {
    const navigation = { name: group.group_type }

    const subNav = group.questions_and_answers
      .map(qa => {
        return qa.faq_navigation_title
      })
      .filter((item, i, ar) => ar.indexOf(item) === i)

    return { ...navigation, subNav }
  })

  return (
    <Layout bookBannerData={bookData}>
      <SEO title={pageTitle} />
      <Container>
        <ImageContainer>
          <Img fluid={image.fluid} />
        </ImageContainer>
        <PageHeader title={title} tagline={tagline} />
        <FAQContent>
          <FaqNavigation
            setFaqGroup={setFaqGroup}
            navigation={navItems}
            refs={revealRefs}
          />
          <AnswersContainer>
            <FAQHeader>
              <FAQTitle>{faq_title.text}</FAQTitle>
              <Text position={"left"}>{faq_group_text.text}</Text>
            </FAQHeader>
            {questions_and_answers.map((group, i) => {
              return (
                <QAContainer key={i} ref={addToRefs}>
                  <Question>
                    <QuestionNumber>{i + 1}.</QuestionNumber>
                    {group.question.text}
                  </Question>
                  <Answer
                    dangerouslySetInnerHTML={{ __html: group.answer.html }}
                  />
                </QAContainer>
              )
            })}
          </AnswersContainer>
        </FAQContent>
      </Container>
    </Layout>
  )
}

export default withPreview(FAQ)

const Container = styled.div`
  width: 80%;
  margin: 0 auto;
  padding: 40px 0 80px 0;
`

const ImageContainer = styled.div`
  .gatsby-image-wrapper {
    max-height: 446px;
    min-height: 250px;
  }
`

const FAQContent = styled.div`
  display: grid;
  grid-template-columns: 220px 1fr;
  grid-gap: 16px;
  @media (max-width: 700px) {
    grid-template-columns: 1fr;
    justify-items: center;
  }
`
const Question = styled.h3`
  font-family: "Canela Light", sans-serif;
  color: var(--primary-text);
`
const AnswersContainer = styled.div`
  padding: 0 8px 24px 8px;
`

const QAContainer = styled.div`
  padding-top: 40px;
`

const FAQTitle = styled.h2`
  margin: 0;
  font-family: "Moderat Regular", sans-serif;
  color: var(--primary-text);
`

const FAQHeader = styled.div``

const QuestionNumber = styled.span`
  padding-right: 24px;
`
